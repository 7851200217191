import React, { Component } from 'react';
import { Navigate } from "react-router-dom";



class Login extends Component {
    constructor(props) {
        super(props);
        let greet = localStorage.getItem("login") || "";
        this.state = {
            pass: '',
            login: greet,
            lgo: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.tryLogin = this.tryLogin.bind(this);
    }

    tryLogin(login, pass) {

        var url = "http://46.175.145.158/";

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url + "mt-api.php", true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = function (e) {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {

                    var resp = JSON.parse(xhr.responseText);
                    if (resp.status) {

                        let skey = localStorage.getItem('login') || "";
                        console.log(skey);
                        console.log(this.state.login);
                        console.log(skey != this.state.login);
                        if (skey != this.state.login)
                            localStorage.clear();
                        localStorage.setItem("lp", "/");
                        localStorage.setItem("lpi", "http://mt.sproba.com.ua/img/");

                        localStorage.setItem("name", resp.name);
                        localStorage.setItem("login", this.state.login);
                        localStorage.setItem("dbname", resp.dbname);
                        localStorage.setItem("url", resp.url);
                        localStorage.setItem("skey", resp.skey);
                        localStorage.setItem("skey1", resp.skey);
                        localStorage.setItem("pass", resp.pass);
                        localStorage.setItem("globalGroup", resp.globalGroup);
                        localStorage.setItem("globalGroupKlient", resp.globalGroupKlient);
                        localStorage.setItem("startsesss", Date.now());

                        this.setState({ log: true });
                    } else {

                        alert("Ошибка авторизации");


                    }

                } else {
                    if ((login == localStorage.getItem("login")) && (pass == localStorage.getItem("pass"))) {
                        localStorage.setItem("skey", localStorage.getItem("skey1"));
                        this.setState({ log: true });
                    } else {
                        alert("Ошибка доступа к серверу авторизации");
                        console.error(xhr.statusText);
                    }
                }
            }
        }.bind(this);
        xhr.onerror = function (e) {
            if ((login == localStorage.getItem("login")) && (pass == localStorage.getItem("pass"))) {
                localStorage.setItem("skey", localStorage.getItem("skey1"));
                this.setState({ log: true });
            } else {
                console.error(xhr.statusText);
                alert("Ошибка доступа к серверу авторизации");
            }
        }.bind(this);
        xhr.send("id=0&login=" + login + "&pass=" + pass);

    }

    handleInputChange(event) {
        const target = event.target;
        const name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (target.type === 'number') { value = Number(value) }

        this.setState({
            [name]: value
        });

    }

    render() {
        var skey = localStorage.getItem("skey") || "";
        if (skey != "") { return (<Navigate to={localStorage.getItem("lp")} replace={true} />); }


        return (
            <div className='logon-body'>
                <div>

                    <h3 className='text-center'>Авторизация</h3>

                    <div className=" form-group">
                        <input
                            name="login"
                            type="text"
                            value={this.state.login}
                            className="form-control "
                            placeholder="Введите имя"
                            onChange={this.handleInputChange}
                        />

                        <input
                            name="pass"
                            type="password"
                            className="form-control "
                            placeholder="Введите пароль"
                            onChange={this.handleInputChange}
                            onKeyUp={(event) => {
                                if (event.keyCode == 13) {
                                    this.tryLogin(this.state.login, this.state.pass)
                                }
                            }} />
                    </div>
                    <button className="btn btn-primary btn-block mt-2 w-100 " onClick={() => { this.tryLogin(this.state.login, this.state.pass) }}>Вход</button>
                </div>
            </div>
        );
    }
}

export default Login;